.i-logo {
  background: transparent url('/assets/images/logo.svg') no-repeat center center;
  padding: 30px;
  margin-top: 5px;
  background-size: 65px;
  cursor: pointer;
  @media only screen and (max-width: 600px) {
      background-size: 45px;
  }
}



.i-sidebar-logo {
    background: transparent url('/assets/images/logo.svg') no-repeat center center;
    padding: 17px;
    // margin-top: 5px;
    background-size: 32px;
    cursor: pointer;
    @media only screen and (max-width: 600px) {
        background-size: 45px;
    }
}

.i-door {
    background: transparent url('/assets/images/door.svg') no-repeat center center;
    padding: 25px;
    margin-top: 5px;
    background-size: 40px;
}

.i-door-sm-b {
    background: transparent url('/assets/images/door_black.svg') no-repeat center center;
    padding: 15px;
    background-size: 23px;
}

.i-door-sm-w {
    background: transparent url('/assets/images/door_white.svg') no-repeat center center;
    padding: 15px;
    background-size: 23px;
}

.i-door-sm-gr {
    background: transparent url('/assets/images/door.svg') no-repeat center center;
    padding: 15px;
    background-size: 23px;
}


.i-rooms-footer {
    background: transparent url('/assets/images/rooms_footer.svg') no-repeat center center;
    // margin-top:-3px;
    padding: 25px;
    background-size: 35px;
}

.i-rooms-sm-w {
    background: transparent url('/assets/images/rooms_blue.svg') no-repeat center center;
    padding: 16px;
    background-size: 36px;
    cursor: pointer;
}

.i-rooms-sm-gr {
    background: transparent url('/assets/images/rooms.svg') no-repeat center center;
    padding: 16px;
    background-size: 36px;
    cursor: pointer;
}

.i-rooms-sm-bl {
    background: transparent url('/assets/images/room_with_lamp.svg') no-repeat center center;
    padding: 25px;
    background-size: 30px;
}

.i-enter-key-wh {
    background: transparent url('/assets/images/enter_key_wh.svg') no-repeat center center;
    background-size: 28px;
}

.i-backspace-wh {
    background: transparent url('/assets/images/backspace_wh.svg') no-repeat center center;
    background-size: 28px;
}

.i-key {
    background: transparent url('/assets/images/key.svg') no-repeat center center;
    padding: 25px;
    margin-top: 5px;
    background-size: 40px;
    cursor: pointer;
}

.i-key-sm-w {
    background: transparent url('/assets/images/key_white.svg') no-repeat center center;
    padding: 14px 17px;
    background-size: 23px;
    cursor: pointer;
}

.i-key-sm-gr {
    background: transparent url('/assets/images/key.svg') no-repeat center center;
    padding: 14px 17px;
    background-size: 23px;
    cursor: pointer;
}

.i-users {
    background: transparent url('/assets/images/users.svg') no-repeat center center;
    padding: 25px;
    margin-top: 5px;
    background-size: 40px;
    cursor: pointer;
}

.i-users-sm-w {
    background: transparent url('/assets/images/users_white.svg') no-repeat center center;
    padding: 15px;
    background-size: 32px;
    cursor: pointer;
}

.i-users-sm-gr {
    background: transparent url('/assets/images/users.svg') no-repeat center center;
    padding: 15px;
    background-size: 32px;
    cursor: pointer;
}

.i-list {
    background: transparent url('/assets/images/list.svg') no-repeat center center;
    padding: 25px;
    margin-top: 5px;
    background-size: 25px;
    cursor: pointer;
}

.i-list-sm-w {
    background: transparent url('/assets/images/list_white.svg') no-repeat center center;
    padding: 15px;
    background-size: 24px;
    cursor: pointer;
}

.i-card-sm{
    background: transparent url('/assets/images/card.svg') no-repeat center center;
    padding: 16px;
    background-size: 25px;
    background-position-y: 17px;
}

.i-add {
    background: transparent url('/assets/images/add.svg') no-repeat center center;
    padding: 20px;
    background-size: 24px;
    &-sm {
        @extend .i-add;
        padding: 15px;
        margin-top: 5px;
        background-size: 20px;
    }
    &-l {
        @extend .i-add;
        background-size: 30px;
    }
}

.i-arrow-right {
  background: transparent url('/assets/images/arrow_right.svg') no-repeat center center;
  padding: 10px;
  background-size: 14px;
}

.i-delete-element {
  background: transparent url('/assets/images/delete.svg') no-repeat center center;
  padding: 10px;
  background-size: 12px;
}

.i-edit {
    background: transparent url('/assets/images/edit.svg') no-repeat center center;
    padding: 16px;
    background-size: 24px;
    cursor: pointer;
    &-sm {
        @extend .i-edit;
        background-position-y: 15px;
        padding: 12px;
        background-size: 20px;
    }
}

.i-delete {
    background: transparent url('/assets/images/delete.svg') no-repeat center center;
    padding: 14px;
    background-size: 22px;
    cursor: pointer;
    &-sm {
        @extend .i-delete;
        background-position-y: 12px;
        padding: 14px;
        background-size: 22px;
    }
    &-xsm {
        @extend .i-delete;
        padding: 10px;
        background-size: 20px;
    }
}

.i-arrow-r-xsm {
    background: transparent url('/assets/images/arrow_r.svg') no-repeat center center;
    padding: 16px;
    background-size: 18px;
    cursor: pointer;
}

.i-arrow-up {
    background: transparent url('/assets/images/arrow_up.svg') no-repeat center center;
    padding: 16px;
    background-size: 18px;
    cursor: pointer;
}

.i-arrow-down {
  background: transparent url('/assets/images/arrow_down.svg') no-repeat center center;
  padding: 12px;
  background-size: 26px;
  cursor: pointer;
}

.i-link-bl {
    background: transparent url('/assets/images/link_bl.svg') no-repeat center center;
    padding: 16px;
    background-size: 18px;
    cursor: pointer;
}

.i-calendar-sm {
    background: transparent url('/assets/images/calendar.svg') no-repeat center center;
    padding: 16px;
    background-size: 32px;
    cursor: pointer;
}

.i-unlock {
    background: transparent url('/assets/images/unlock.svg') no-repeat center center;
    padding: 16px;
    background-size: 27px;
    cursor: pointer;
    &-sm {
        @extend .i-unlock;
        background-position-y: 20px;
        background-size: 22px;
    }
}


.i-clock-add {
    background: transparent url('/assets/images/clock_add.svg') no-repeat center center;
    padding: 12px;
    background-size: 22px;
    cursor: pointer;
    &-sm {
        @extend .i-clock-add;
        background-size: 22px;
        padding-bottom: 17px;
    }
}

.i-details-sm {
    background: transparent url('/assets/images/details.svg') no-repeat center center;
    padding: 16px;
    background-size: 20px;
    cursor: pointer;
}

.i-access-sm {
  background: transparent url('/assets/images/access.svg') no-repeat center center;
  padding: 16px;
  background-size: 22px;
}

.i-door-and-card {
  background: transparent url('/assets/images/door_card_gray.svg') no-repeat center center;
  padding: 16px;
  background-size: 26px;
}

.i-access-sm-blue {
  background: transparent url('/assets/images/access_blue.svg') no-repeat center center;
  padding: 16px;
  background-size: 22px;
}

/* .i-checkmark-xsm {
    background: transparent url('/assets/images/checkmark.svg') no-repeat center center;
    padding: 8px;
    background-size: 12px;
} */

/* .i-checkmark-round {
  background: transparent url('/assets/images/checkmark-round.svg') no-repeat center center;
  padding: 16px;
  background-size: 28px;
} */

.i-checkmark {
  background: transparent url('/assets/images/checkmark.svg') no-repeat center center;
  padding: 16px;
  background-size: 24px;
  cursor: pointer;
}

.i-reader-sm {
    background: transparent url('/assets/images/reader.svg') no-repeat center center;
    padding: 16px;
    background-size: 32px;
}

.i-reader-denied-sm {
    background: transparent url('/assets/images/reader_deny.svg') no-repeat center center;
    padding: 16px;
    background-size: 32px;
}

.i-alert {
    background: transparent url('/assets/images/alert_gr.svg') no-repeat center center;
    padding: 25px;
    background-size: 40px;
}

.i-alert-sm {
    background: transparent url('/assets/images/alert.svg') no-repeat center center;
    padding: 10px;
    background-size: 18px;
}

.i-alert-sm-black {
    background: transparent url('/assets/images/alert_black.svg') no-repeat center center;
    padding: 20px 10px 20px 10px;
    background-size: 19px;
}

.i-alert-sm-w {
    background: transparent url('/assets/images/alert_w.svg') no-repeat center center;
    padding: 14px 17px;
    background-size: 23px;
}

.i-alert-sm-gr {
    background: transparent url('/assets/images/alert_gr.svg') no-repeat center center;
    padding: 14px 17px;
    background-size: 23px;
}

.i-alarm-log {
    background: transparent url('/assets/images/alarm_log.svg') no-repeat center center;
    padding: 25px;
    background-size: 35px;
    cursor: pointer;
}

.i-alarm-log-sm-w {
    background: transparent url('/assets/images/alarm_log_w.svg') no-repeat center center;
    padding: 16px 16px;
    background-size: 28px;
    cursor: pointer;
}

.i-access-log-sm-wh {
  background: transparent url('/assets/images/access_log_w.svg') no-repeat center center;
  padding: 16px 16px;
  background-size: 28px;
  cursor: pointer;
}

.i-alarm-log-sm-gr {
    background: transparent url('/assets/images/alarm_log.svg') no-repeat center center;
    padding: 16px 16px;
    background-size: 28px;
    cursor: pointer;
}

.i-door-locked-status {
    background: transparent url('/assets/images/door_locked_status.svg') no-repeat center center;
    padding: 16px;
    background-size: 27px;
    &-sm {
        @extend .i-door-locked-status;
        background-position-y: 20px;
        background-size: 22px;
    }
}

.i-door-unlocked-status {
    background: transparent url('/assets/images/door_unlocked_status.svg') no-repeat center center;
    padding: 16px;
    background-size: 27px;
    &-sm {
        @extend .i-door-unlocked-status;
        background-size: 22px;
    }
}

.i-door-opened-status {
    background: transparent url('/assets/images/door_opened_status.svg') no-repeat center center;
    padding: 16px;
    background-size: 27px;
    &-sm {
        @extend .i-door-opened-status;
        background-position-y: 20px;
        background-size: 22px;
    }
}

.i-door-opened-black {
  background: transparent url('/assets/images/door_opened_black.svg') no-repeat center center;
  padding: 16px;
  background-size: 27px;
}

.i-door-closed-status {
    background: transparent url('/assets/images/door_closed_status.svg') no-repeat center center;
    padding: 16px;
    background-size: 27px;
    &-sm {
        @extend .i-door-closed-status;
        background-position-y: 20px;
        background-size: 22px;
    }
}

.i-door-closed-black {
  background: transparent url('/assets/images/door_closed_black.svg') no-repeat center center;
  padding: 16px;
  background-size: 27px;
}

.i-transfer {
    background: transparent url('/assets/images/transfer.svg') no-repeat center center;
    padding: 16px;
    background-size: 20px;
    background-position-y: 20px;
    cursor: pointer;
}

.i-user-list {
    background: transparent url('/assets/images/user_list.svg') no-repeat center center;
    padding: 16px;
    background-size: 32px;
    cursor: pointer;
}

.i-user-wh {
    background: transparent url('/assets/images/user_wh.svg') no-repeat center center;
    padding: 15px;
    background-size: 32px;
    cursor: pointer;
}

.i-user-gr {
    background: transparent url('/assets/images/user_gr.svg') no-repeat center center;
    padding: 15px;
    background-size: 32px;
    cursor: pointer;
}

.i-acknowledge {
    background: transparent url('/assets/images/acknowledge.svg') no-repeat center center;
    padding: 16px;
    background-size: 32px;
    cursor: pointer;
}

.i-acknowledge-danger {
    background: transparent url('/assets/images/acknowledge_danger.svg') no-repeat center center;
    padding: 16px;
    background-size: 32px;
    cursor: pointer;
}

.i-acknowledge-warning {
    background: transparent url('/assets/images/acknowledge_warning.svg') no-repeat center center;
    padding: 16px;
    background-size: 32px;
    cursor: pointer;
}

.i-back-arrow{
  background: transparent url('/assets/images/back_arrow.svg') no-repeat center center;
    padding: 16px;
    background-size: 24px;
    cursor: pointer;
    margin-top: 3px;
}

.i-forward-arrow{
    background: transparent url('/assets/images/forward_arrow.svg') no-repeat center center;
      padding: 12px;
      background-size: 20px;
      cursor: pointer;
  }

.i-forward-arrow-small{
  background: transparent url('/assets/images/forward_arrow.svg') no-repeat center center;
    padding: 16px;
    background-size: 20px;
    cursor: pointer;
}

.i-forward-arrow-blue{
background: transparent url('/assets/images/forward_arrow_blue.svg') no-repeat center center;
    padding: 16px;
    background-size: 24px;
    cursor: pointer;
}

.i-three-vert-dots{
  background: transparent url('/assets/images/three-vert-dots.svg') no-repeat center center;
  padding: 16px;
  background-size: 24px;
    cursor: pointer;
}

/* .i-three-vert-dots-footer{
  background: transparent url('/assets/images/three-vert-dots.svg') no-repeat center center;
  padding: 25px;
  background-size: 30px;
  margin-top: 5px;
  cursor: pointer;
} */

.i-plus-rounded{
    background: transparent url('/assets/images/plus.svg') no-repeat center center;
      padding: 16px;
      background-size: 24px;
      cursor: pointer;

  }

  .i-minus-rounded{
    background: transparent url('/assets/images/minus.svg') no-repeat center center;
      padding: 16px;
      background-size: 24px;
      cursor: pointer;

  }

  .i-plus-rounded-wh{
    background: transparent url('/assets/images/plus_wh.svg') no-repeat center center;
      padding: 16px;
      background-size: 24px;
      cursor: pointer;

  }

  .i-minus-rounded-wh{
    background: transparent url('/assets/images/minus_wh.svg') no-repeat center center;
      padding: 16px;
      background-size: 24px;
      cursor: pointer;

  }

  .i-power-wh{
    background: transparent url('/assets/images/power_wh.svg') no-repeat center center;
      padding: 16px;
      background-size: 24px;
      cursor: pointer;
  }

.i-access-log {
    background: transparent url('/assets/images/access_log_grey.svg') no-repeat center center;
    padding: 25px;
    background-size: 35px;
    cursor: pointer;
}

.i-access-log-sm-w {
    background: transparent url('/assets/images/access-log.svg') no-repeat center center;
    padding: 14px 17px;
    background-size: 23px;
    cursor: pointer;
}

.i-access-log-sm-gr {
    background: transparent url('/assets/images/access_log_grey.svg') no-repeat center center;
    padding: 14px 17px;
    background-size: 23px;
    cursor: pointer;
}

.i-audit-log {
    background: transparent url('/assets/images/audit_log_grey.svg') no-repeat center center;
    padding: 25px;
    background-size: 35px;
    cursor: pointer;
}

.i-audit-log-sm-w {
    background: transparent url('/assets/images/audit-log.svg') no-repeat center center;
    padding: 18px 16px;
    background-size: 29px;
    cursor: pointer;
}

.i-audit-log-sm-gr {
    background: transparent url('/assets/images/audit_log_grey.svg') no-repeat center center;
    padding: 18px 16px;
    background-size: 29px;
    cursor: pointer;
}

.i-refresh-sm {
    background: transparent url('/assets/images/refresh.svg') no-repeat center center;
    padding: 15px;
    background-size: 23px;
    cursor: pointer;
}

.i-reset-sm {
    background: transparent url('/assets/images/reset.svg') no-repeat center center;
    padding: 15px;
    background-size: 25px;
    cursor: pointer;
}

.i-sort-bl {
    background: transparent url('/assets/images/sort_bl.svg') no-repeat center center;
    padding: 15px;
    background-size: 30px;
    cursor: pointer;
}

.i-graph-bl {
    background: transparent url('/assets/images/graph_bl.svg') no-repeat center center;
    padding: 14px;
    background-size: 26px;
    cursor: pointer;
}

.i-play-bl {
    background: transparent url('/assets/images/play_bl.svg') no-repeat center center;
    padding: 24px;
    background-size: 40px;
    cursor: pointer;
}

.i-x-xsm {
    background: transparent url('/assets/images/x.svg') no-repeat center center;
    padding: 14px 17px;
    background-size: 12px;
    cursor: pointer;
}

.i-live-log-sm-w {
  background: transparent url('/assets/images/live-log.svg') no-repeat center center;
  padding: 14px 17px;
  background-size: 23px;
  cursor: pointer;
}

.i-live-log-sm-gr {
    background: transparent url('/assets/images/live_log_grey.svg') no-repeat center center;
    padding: 14px 17px;
    background-size: 23px;
    cursor: pointer;
}

.i-alarm-sm-gr {
    background: transparent url('/assets/images/alarm.svg') no-repeat center center;
    padding: 14px 17px;
    background-size: 23px;
    cursor: pointer;
}

.i-project {
    background: transparent url('/assets/images/project_white.svg') no-repeat center center;
    padding: 25px;
    margin-top: 5px;
    background-size: 40px;
    cursor: pointer;
}

.i-project-sm-w {
    background: transparent url('/assets/images/project_white.svg') no-repeat center center;
    padding: 16px;
    background-size: 32px;
    cursor: pointer;
    margin-top: 2px
}

.i-project-sm-gr {
    background: transparent url('/assets/images/project.svg') no-repeat center center;
    padding: 16px;
    background-size: 32px;
    cursor: pointer;
    margin-top: 2px
}

.i-scenes-bl {
    background: transparent url('/assets/images/scenes-bl.svg') no-repeat center center;
    padding: 20px;
    background-size: 31px;
  }

.i-scenes-orange {
  background: transparent url('/assets/images/scenes-orange.svg') no-repeat center center;
  padding: 16px ;
  background-size: 32px ;
  cursor: pointer ;
}

  .i-scenes-footer {
    background: transparent url('/assets/images/scenes-footer.svg') no-repeat center center;
    padding: 25px;
    margin-top: -5px;
    background-size: 35px;
  }

.i-scenes-sm-w {
  background: transparent url('/assets/images/scenes-w.svg') no-repeat center center;
  padding: 14px;
  background-size: 28px;
  margin-left: 2px;
  cursor: pointer;
}

.i-scenes-sm-blue {
  background: transparent url('/assets/images/scenes-blue.svg') no-repeat center center;
  padding: 14px;
  background-size: 28px;
  margin-left: 2px;
  cursor: pointer;
}

.i-scenes-sm-gr {
  background: transparent url('/assets/images/scenes-gr.svg') no-repeat center center;
  padding: 14px;
  background-size: 28px;
  margin-left: 2px;
  cursor: pointer;
}

.i-scenes-sm-white {
  background: transparent url('/assets/images/scenes-white.svg') no-repeat center center;
  padding: 16px;
  background-size: 32px;
  cursor: pointer;
}

.i-scenes-sm-bl {
  background: transparent url('/assets/images/scenes-bl.svg') no-repeat center center;
  padding: 12px;
  background-size: 26px;
  cursor: pointer;
}

.i-modes-bl {
    background: transparent url('/assets/images/modes-bl.svg') no-repeat center center;
    padding: 20px;
    background-size: 28px;
  }

.i-modes-sm-w {
    background: transparent url('/assets/images/modes-w.svg') no-repeat center center;
    padding: 15px;
    background-size: 23px;
    cursor: pointer;
  }

  .i-modes-sm-blue {
    background: transparent url('/assets/images/modes-blue.svg') no-repeat center center;
    padding: 15px;
    background-size: 23px;
    cursor: pointer;
  }

  .i-modes-sm-gr {
    background: transparent url('/assets/images/modes-gr.svg') no-repeat center center;
    padding: 15px;
    background-size: 23px;
    cursor: pointer;
  }

  .i-mode-heating {
    background: transparent url('/assets/images/heating.svg') no-repeat center center;
    padding: 15px;
    background-size: 18px;
    cursor: pointer;
  }

  .i-mode-cooling {
    background: transparent url('/assets/images/cooling_wh.svg') no-repeat center center;
    padding: 15px;
    background-size: 18px;
    cursor: pointer;
  }

  .i-mode-arm-active {
    background: transparent url('/assets/images/arm_active.svg') no-repeat center center;
    padding: 15px;
    background-size: 18px;
    cursor: pointer;
  }

  .i-mode-arm-black {
    background: transparent url('/assets/images/arm_active_black.svg') no-repeat center center;
    padding: 15px;
    background-size: 18px;
    cursor: pointer;
  }

  .i-mode-arm-black {
    background: transparent url('/assets/images/arm_active_black.svg') no-repeat center center;
    padding: 15px;
    background-size: 18px;
    cursor: pointer;
  }

  .i-mode-arm-inactive {
    background: transparent url('/assets/images/arm_inactive.svg') no-repeat center center;
    padding: 15px;
    background-size: 18px;
    cursor: pointer;
  }

  .i-mode-sih-active{
    background: transparent url('/assets/images/someone_in_house.svg') no-repeat center center;
    padding: 15px;
    background-size: 32px;
    cursor: pointer;
  }

  .i-mode-sih-active-sm{
    background: transparent url('/assets/images/someone_in_house.svg') no-repeat center center;
    padding: 12px;
    background-size: 23px;
    cursor: pointer;
  }

  .i-mode-sih-inactive{
    background: transparent url('/assets/images/someone_in_house_inactive.svg') no-repeat center center;
    padding: 15px;
    background-size: 32px;
    cursor: pointer;
  }

.i-automation-sm-blue {
    background: transparent url('/assets/images/automation_blue.svg') no-repeat center center;
    padding: 16px;
    cursor: pointer;
}

.i-automation-sm-gr {
    background: transparent url('/assets/images/automation_gr.svg') no-repeat center center;
    padding: 16px;
    cursor: pointer;
}

.i-automation-sm-bl {
    background: transparent url('/assets/images/automation_bl.svg') no-repeat center center;
    padding: 15px;
    background-size: 30px;
}

.i-automation {
  background: transparent url('/assets/images/automation_gr.svg') no-repeat center center;
  padding: 25px;
  margin-top: 5px;
  background-size: 40px;
  cursor: pointer;
}

.i-automation-footer {
    background: transparent url('/assets/images/automation_footer.svg') no-repeat center center;
    padding: 25px;
    // margin-top: -3px;
    background-size: 36px;
    cursor: pointer;
  }

  .i-home-footer {
    background: transparent url('/assets/images/home_footer.svg') no-repeat center center;
    padding: 25px;
    //margin-top: -2px;
    background-size: 40px;
    cursor: pointer;
  }

.i-settings {
    background: transparent url('/assets/images/settings-gr.svg') no-repeat center center;
    padding: 25px;
    background-size: 40px;
}

.i-settings-bl {
  background: transparent url('/assets/images/settings_bl.svg') no-repeat center center;
  padding: 20px;
  background-size: 34px;
}



.i-settings-sm-w {
    background: transparent url('/assets/images/settings-w.svg') no-repeat center center;
    padding: 14px 17px;
    background-size: 23px;
}
.i-settings-sm-blue {
  background: transparent url('/assets/images/settings-blue.svg') no-repeat center center;
  padding: 14px 17px;
  background-size: 23px;
}

.i-settings-sm-gr {
    background: transparent url('/assets/images/settings-gr.svg') no-repeat center center;
    padding: 14px 17px;
    background-size: 23px;
}

.i-blind-arrow-up {
    background: transparent url('/assets/images/blind-arrow-up.svg') no-repeat center center;
    padding: 14px 17px;
    background-size: 23px;
}

.i-blind-arrow-down {
    background: transparent url('/assets/images/blind-arrow-down.svg') no-repeat center center;
    padding: 14px 17px;
    background-size: 23px;
}

.i-blinds {
    background: transparent url('/assets/images/blinds.svg') no-repeat center center;
    padding: 20px;
    background-size: 26px;
}

.i-blinds-gray {
    background: transparent url('/assets/images/blinds-gray.svg') no-repeat center center;
    padding: 25px;
    background-size: 26px;
}

.i-blinds-orange {
  background: transparent url('/assets/images/blinds-orange.svg') no-repeat center center;
  padding: 25px;
  background-size: 26px;
}

/* .i-light-bulb-off {
    background: transparent url('/assets/images/light-bulb-off.svg') no-repeat center center;
    padding: 20px;
    background-size: 30px;
} */

/* .i-light-bulb-off-gray {
    background: transparent url('/assets/images/light-bulb-off-gray.svg') no-repeat center center;
    padding: 25px;
    background-size: 30px;
} */



/* .i-light-bulb-on {
    background: transparent url('/assets/images/light-bulb-on.svg') no-repeat center center;
    padding: 25px;
    background-size: 30px;
} */


.i-light-rgb {
  background: transparent url('/assets/images/lightbulb-rgb.svg') no-repeat center center;
  padding: 15px 15px ;
  background-size: 15px;
}

.i-light-rgb-orange {
  background: transparent url('/assets/images/lightbulb-rgb-orange.svg') no-repeat center center;
  padding: 15px 15px ;
  background-size: 15px;
}

.i-light-rgbw {
  background: transparent url('/assets/images/lightbulb-rgbw.svg') no-repeat center center;
  padding: 20px 10px 20px 10px;
  background-size: 20px;
}

.i-light-rgbw-automation {
  background: transparent url('/assets/images/lightbulb-rgbw.svg') no-repeat center center;
  padding: 18px;
  background-size: 22px;
}

.i-light-rgbw-orange {
  background: transparent url('/assets/images/lightbulb-rgbw-orange.svg') no-repeat center center;
  padding: 20px 10px 20px 10px;
  background-size: 20px;
}

.i-light-bulb-on-w {
    background: transparent url('/assets/images/light-bulb-on-w.svg') no-repeat center center;
    padding: 25px;
    background-size: 30px;
}

/* .i-light-bulb-on-yellow {
    background: transparent url('/assets/images/light-bulb-on-yellow.svg') no-repeat center center;
    padding: 25px;
    background-size: 30px;
} */

.i-lightgroup-black {
  background: transparent url('/assets/images/lightgroup-black.svg') no-repeat center center;
  padding: 18px;
  background-size: 30px;
}

.i-lightgroup-orange {
  background: transparent url('/assets/images/lightgroup-orange.svg') no-repeat center center;
  padding: 18px;
  background-size: 30px;
}

.i-lightgroup-black-large {
  background: transparent url('/assets/images/lightgroup-black.svg') no-repeat center center;
  padding: 30px;
  background-size: 60px;
}

.i-lightbulb-orange {
  background: transparent url('/assets/images/lightbulb-orange.svg') no-repeat center center;
  padding: 18px;
  background-size: 22px;
}

.i-lightbulb-gray {
  background: transparent url('/assets/images/lightbulb-gray.svg') no-repeat center center;
  padding: 18px;
  background-size: 22px;
}

.i-lightbulb-black-small {
  background: transparent url('/assets/images/lightbulb-black.svg') no-repeat center center;
  padding: 14px;
  background-size: 16px;
}

.i-lightbulb-black {
  background: transparent url('/assets/images/lightbulb-black.svg') no-repeat center center;
  padding: 18px;
  background-size: 22px;
}

.i-keypad-black {
  background: transparent url('/assets/images/keypad_black.svg') no-repeat center center;
  padding: 18px;
  background-size: 22px;
}

.i-keypad-white {
  background: transparent url('/assets/images/keypad_white.svg') no-repeat center center;
  padding: 18px;
  background-size: 22px;
}

/* .i-lightbulb-black-large {
  background: transparent url('/assets/images/lightbulb-black.svg') no-repeat center center;
  padding: 18px;
  background-size: 22px;
} */

/* .i-lightbulb-black-md {
  background: transparent url('/assets/images/lightbulb-black.svg') no-repeat center center;
  padding: 16px;
  background-size: 27px;
} */

.i-dimmable-light {
    background: transparent url('/assets/images/dimmable_light.svg') no-repeat center center;
    padding: 25px;
    background-size: 30px;
}

.i-hvac {
    background: transparent url('/assets/images/hvac.svg') no-repeat center center;
    padding: 20px;
    background-size: 30px;
}

.i-hvac-orange {
    background: transparent url('/assets/images/hvac-orange.svg') no-repeat center center;
    padding: 20px;
    background-size: 30px;
}

.i-hvac-gray {
    background: transparent url('/assets/images/hvac-gray.svg') no-repeat center center;
    padding: 25px;
    background-size: 30px;
}

.i-general-equipment-bl {
    background: transparent url('/assets/images/general_equipment_bl.svg') no-repeat center center;
    padding: 25px;
    background-size: 30px;
}

.i-general-equipment-gray {
    background: transparent url('/assets/images/general_equipment_gray.svg') no-repeat center center;
    padding: 25px;
    background-size: 30px;
}

.i-general-equipment-orange {
  background: transparent url('/assets/images/general_equipment_orange.svg') no-repeat center center;
  padding: 25px;
  background-size: 30px;
}


.i-safety-equipment-bl {
  background: transparent url('/assets/images/safety_equipment_bl.svg') no-repeat center center;
  padding: 20px;
  background-size: 30px;
}

.i-safety-equipment-gray {
    background: transparent url('/assets/images/safety_equipment_gray.svg') no-repeat center center;
    padding: 25px;
    background-size: 30px;
  }

  .i-safety-equipment-orange {
    background: transparent url('/assets/images/safety_equipment_orange.svg') no-repeat center center;
    padding: 25px;
    background-size: 30px;
  }

.i-security-equipment-bl {
  background: transparent url('/assets/images/security_equipment_bl.svg') no-repeat center center;
  padding: 20px;
  background-size: 23px;
}

.i-security-equipment-gray {
    background: transparent url('/assets/images/security_equipment_gray.svg') no-repeat center center;
    padding: 25px;
    background-size: 23px;
  }

  .i-security-equipment-orange {
    background: transparent url('/assets/images/security_equipment_orange.svg') no-repeat center center;
    padding: 25px;
    background-size: 23px;
  }


.i-logout-wh {
    background: transparent url('/assets/images/logout_wh.svg') no-repeat center center;
    padding: 15px;
    background-size: 23px;
    cursor: pointer;
}

// ICONS FOR CONTAINERS
 .i-blinds-icon:before {
    @extend .i-blinds;
    content: '';
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 3;
    font-size: 140%;
    width: 60px;
    border-radius: 50px 0 0 50px;
}


/* .i-light-bulb-off-icon:before {
    @extend .i-light-bulb-off;
    content: '';
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 3;
    font-size: 140%;
    width: 60px;
    border-radius: 50px 0 0 50px;
} */

.i-light-bulb-off-gray-icon:before {
    @extend .i-lightbulb-gray;
    content: '';
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 3;
    font-size: 140%;
    width: 60px;
    border-radius: 50px 0 0 50px;
}

/* .i-light-bulb-on-icon:before {
    @extend .i-light-bulb-on;
    content: '';
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 3;
    font-size: 140%;
    width: 60px;
    border-radius: 50px 0 0 50px;
} */

.i-dimmable-light-icon:before {
    @extend .i-dimmable-light;
    content: '';
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 3;
    font-size: 140%;
    width: 60px;
    border-radius: 50px 0 0 50px;
}

.i-hvac-icon:before {
    @extend .i-hvac;
    content: '';
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 3;
    font-size: 140%;
    width: 60px;
    border-radius: 50px 0 0 50px;
}

.i-automation-icon:before {
    @extend .i-automation-sm-bl;
    content: '';
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 3;
    font-size: 140%;
    width: 60px;
    border-radius: 50px 0 0 50px;
}

.i-rooms-icon:before {
    @extend .i-rooms-sm-bl;
    content: '';
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 3;
    font-size: 140%;
    width: 60px;
    border-radius: 50px 0 0 50px;
}

.i-general-equipment-icon:before {
    @extend .i-general-equipment-bl;
    content: '';
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 3;
    font-size: 140%;
    width: 60px;
    border-radius: 50px 0 0 50px;
}


.i-scenes-icon:before {
  @extend .i-scenes-sm-bl;
  content: '';
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  line-height: 3;
  font-size: 140%;
  width: 60px;
  border-radius: 50px 0 0 50px;
}



.i-safety-equipment-icon:before {
  @extend .i-safety-equipment-bl;
  content: '';
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  line-height: 3;
  font-size: 140%;
  width: 60px;
  border-radius: 50px 0 0 50px;
}

 .i-security-equipment-icon:before {
  @extend .i-security-equipment-bl;
  content: '';
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  line-height: 3;
  font-size: 140%;
  width: 60px;
  border-radius: 50px 0 0 50px;
}


.i-random{
  background: transparent url('/assets/images/random.svg') no-repeat center center;
    padding: 16px;
    background-size: 24px;
    cursor: pointer;

}


.i-apartment {
  background: transparent url('/assets/images/apartment.svg') no-repeat center center;
  padding: 25px;
  margin-top: 5px;
  background-size: 40px;
}

.i-sunrise {
  background: transparent url('/assets/images/sunrise.svg') no-repeat center center;
  padding: 25px;
  background-size: 30px;
}

.i-sunset {
  background: transparent url('/assets/images/sunset.svg') no-repeat center center;
  padding: 25px;
  background-size: 30px;
}

.i-noun-clock {
  background: transparent url('/assets/images/noun_clock.svg') no-repeat center center;
  padding: 25px;
  background-size: 25px;
}

.i-noun-clock-bl {
  background: transparent url('/assets/images/noun_clock_bl.svg') no-repeat center center;
  padding: 25px;
  background-size: 25px;
}


.i-cooling-sm-white {
  background: transparent url('/assets/images/cooling_wh.svg') no-repeat center center;
  padding: 10px;
  background-size: 18px;
}

.i-heating-sm-white {
  background: transparent url('/assets/images/heating_mode_white.svg') no-repeat center center;
  padding: 10px;
  background-size: 18px;
}

.i-cooling-sm-black {
  background: transparent url('/assets/images/cooling_bl.svg') no-repeat center center;
  padding: 14px;
  background-size: 18px;
}

.i-heating-sm-black {
  background: transparent url('/assets/images/heating_mode_black.svg') no-repeat center center;
  padding: 14px;
  background-size: 18px;
}

.i-heating-cooling-sm-black {
  background: transparent url('/assets/images/heating_cooling_mode_black.svg') no-repeat center center;
  padding: 14px;
  background-size: 18px;
}

.i-armed-sm-bl {
  background: transparent url('/assets/images/armed_bl.svg') no-repeat center center;
  padding: 10px;
  background-size: 18px;
}

.i-local-sm-bl {
    background: transparent url('/assets/images/local.svg') no-repeat center center;
    padding: 18px;
    background-size: 28px;
}

.i-internet-sm-bl {
    background: transparent url('/assets/images/internet.svg') no-repeat center center;
    padding: 16px;
    background-size: 24px;
}

.i-no-connection-sm-bl {
    background: transparent url('/assets/images/no_connection.svg') no-repeat center center;
    padding: 16px;
    background-size: 28px;
}

.i-wifi-sm-bl {
  background: transparent url('/assets/images/wifi.svg') no-repeat center center;
  padding: 16px;
  background-size: 24px;
}

.i-fan-md-bl {
  background: transparent url('/assets/images/fan_bl.svg') no-repeat center center;
  padding: 16px;
  background-size: 23px;
}




.i-window-open-md-bl {
  background: transparent url('/assets/images/window_bl.svg') no-repeat center center;
  padding: 13px;
  background-size: 23px;
}

.i-door-open-md-bl {
  background: transparent url('/assets/images/door_opened_status_bl.svg') no-repeat center center;
  padding: 11px;
  background-size: 21px;
}


.i-switch-view {
  background: transparent url('/assets/images/switch_view.svg') no-repeat center center;
  padding: 15px;
  background-size: 20px;
}
.i-triangle-error-md-bl {
    background: transparent url('/assets/images/triangle_error_bl.svg') no-repeat center center;
    padding: 16px;
    background-size: 24px;
  }

  .i-analytics-sm-w {
    background: transparent url('/assets/images/analytics-w.svg') no-repeat center center;
    padding: 15px;
    background-size: 35px;
    cursor: pointer;
  }

  .i-language-bubble {
    background: transparent url('/assets/images/language_bubble.svg') no-repeat center center;
    padding: 20px 10px 20px 10px;
    background-size: 22px;
}

.i-admin-settings {
    background: transparent url('/assets/images/admin_settings.svg') no-repeat center center;
    padding: 20px 10px 20px 10px;
    background-size: 22px;
}

.i-settings-bl-mobile {
    background: transparent url('/assets/images/settings_bl.svg') no-repeat center center;
    padding: 20px 10px 20px 10px;
    background-size: 22px;
  }

  .i-copy {
    background: transparent url('/assets/images/copy.svg') no-repeat center center;
    padding: 15px 15px;
    background-size: 32px;
  }

  .i-network-error {
    background: transparent url('/assets/images/network_error.svg') no-repeat center center;
    padding: 25px;
    margin-top: 5px;
    background-size: 40px;
  }
